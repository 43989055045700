import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full text-primary-300`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ cards = null, heading = (<> <span tw="text-secondary-500"> Innovating security</span>, tailored to you </>), subheading = "", description = "MyLock's smart lockers and multi-platform software deliver top-notch security features, seamless user experience, and personalized service customization." }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    { 
      imageSrc: ShieldIconImage, 
      title: "Security", 
      description: "Safeguard your content worry-free in our smart lockers, even during power outages or internet disruptions.",
      altText: "Shield icon symbolizing robust security features" 
    },
    { 
      imageSrc: SupportIconImage, 
      title: "24/7 Support", 
      description: "Our virtual assistant and support team are here for you 24/7 for any assistance you need.",
      altText: "Support icon representing 24/7 customer service availability" 
    },
    { 
      imageSrc: CustomizeIconImage, 
      title: "Customizable", 
      description: "We tailor our service to meet every client's needs, from design to functionality.",
      altText: "Customizable icon illustrating flexibility in design and functionality" 
    },
    { 
      imageSrc: ReliableIconImage, 
      title: "Remote", 
      description: "Our flagship storage lockers enables remote operations and real-time locker status monitoring via remote portal.",
      altText: "Remote icon symbolizing operations from a distance with real-time monitoring" 
    },
    { 
      imageSrc: FastIconImage, 
      title: "Fast", 
      description: "We've crafted software for lightning-fast user authentication. Intuitive, hassle-free smart lockers, Seconds away.",
      altText: "Fast icon depicting quick, efficient authentication processes" 
    },
    { 
      imageSrc: SimpleIconImage, 
      title: "Data Science", 
      description: "The remote management portal presents processed data and graphs for actionable insights into lockers usage.",
      altText: "Data science icon highlighting insights through data and statistics" 
    }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container id="Different">
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt={card.altText || "Default shield icon"}/>
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "storage lockers and smart lockers with virtual keys and advanced remote management and tailored features."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
