import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";
import { ContentWithPaddingXl, Container as ContainerBase } from "components/misc/Layouts";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import appleIconImageSrc from "images/apple-icon.png";
import googlePlayIconImageSrc from "images/google-play-icon.png";
import LazyLoad from "components/LazyLoad"; // Import LazyLoad

const Container = tw(ContainerBase)`bg-gray-900 -mx-8`
const Content = tw(ContentWithPaddingXl)``;
const Row = tw.div`px-8 flex items-center relative z-10 flex-col lg:flex-row text-center lg:text-left justify-center`;

const ColumnContainer = tw.div`max-w-2xl`;
const TextContainer = tw(ColumnContainer)``;
const Text = tw(SectionHeading)`text-gray-100 lg:text-left max-w-none text-xl leading-snug`;
const Subheading = tw(SubheadingBase)`text-3xl mb-4 tracking-wider`;

const LinksContainer = tw.div`mt-8 lg:mt-16 flex flex-col items-center sm:block`;
const Link = styled.a`
  ${tw`w-56 p-3 sm:p-4 text-sm sm:text-base font-bold uppercase tracking-wider rounded-full inline-flex justify-center items-center mt-6 first:mt-0 sm:mt-0 sm:ml-8 first:ml-0 bg-gray-100 hocus:bg-gray-300 text-gray-900 hocus:text-gray-900 shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`}
  img {
    ${tw`inline-block h-8 mr-3`}
  }
  span {
    ${tw`leading-none inline-block text-base`}
  }
`;

const ImageContainer = tw(ColumnContainer)`mt-16 lg:mt-0 lg:ml-16 flex justify-end`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = tw(SvgDecoratorBlob1)`absolute bottom-0 left-0 w-80 h-80 transform -translate-x-20 translate-y-32 text-gray-800 opacity-50`;
const DecoratorBlob2 = tw(SvgDecoratorBlob1)`absolute top-0 right-0 w-80 h-80 transform  translate-x-20 -translate-y-64 text-gray-800 opacity-50`;

export default ({
  subheading = "MyLock App for B2C business",
  text = "Download our App to start using a pay-per-use smart lockers service or to check if it matches your lockers storage business requirements!",
  link1Text = "iOS",
  link1Url = "http://app.mylock.es",
  link1IconSrc = appleIconImageSrc,
  link2Text = "Android",
  link2Url = "http://app.mylock.es",
  link2IconSrc = googlePlayIconImageSrc,
  pushDownFooter = false,
}) => {
  return (
    <Container id="App" css={pushDownFooter && tw`mb-20 lg:mb-24`}>
      <Content>
        <Row>
          <TextContainer>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Text>{text}</Text>
            <LinksContainer>
              <Link href={link1Url} target="_blank" rel="noopener noreferrer">
                <img src={link1IconSrc} alt="Download on iOS" />
                <span>{link1Text}</span>
              </Link>
              <Link href={link2Url} target="_blank" rel="noopener noreferrer">
                <img src={link2IconSrc} alt="Get it on Android" />
                <span>{link2Text}</span>
              </Link>
            </LinksContainer>
          </TextContainer>
          <ImageContainer>
            <LazyLoad>
              <img
                 srcSet="
                 https://storage.googleapis.com/mylock-web.appspot.com/app-mockup-mylock_500x500.webp 500w,
                 https://storage.googleapis.com/mylock-web.appspot.com/app-mockup-mylock_800x800.webp 800w,
                 https://storage.googleapis.com/mylock-web.appspot.com/app-mockup-mylock_1200x1200.webp 1200w,
                 https://storage.googleapis.com/mylock-web.appspot.com/app-mockup-mylock_2000x2000.webp 2000w"
               sizes="(max-width: 500px) 100vw, (max-width: 800px) 80vw, (max-width: 1200px) 50vw, 33vw"
               src="https://storage.googleapis.com/mylock-web.appspot.com/app-mockup-mylock_2000x2000.webp"  // Fallback for browsers that don't support srcSet
               alt="MyLock App for B2C smart lockers and storage lockers mockup"
               tw="w-64"
              />
            </LazyLoad>
          </ImageContainer>
        </Row>
        <DecoratorBlobContainer>
          <DecoratorBlob1 />
          <DecoratorBlob2 />
        </DecoratorBlobContainer>
      </Content>
    </Container>
  );
};
