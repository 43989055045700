  import React from "react";
  import tw from "twin.macro";
  import styled from "styled-components";
  import { css } from "styled-components/macro"; //eslint-disable-line
  import { Helmet } from "react-helmet";  // Import React Helmet for meta tags

  <Helmet>
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Product",
        "name": "WhatsApp Smart Lockers",
        "description": "AI-driven smart lockers powered by natural language processing models, enabling users to select lockers, make payments, and open doors within a simple WhatsApp conversation.",
        "image": "https://mylock.es/thumbnails/capture_lockers_whatsapp.webp",
        "brand": {
          "@type": "Brand",
          "name": "LockMe"
        },
        "offers": {
          "@type": "Offer",
          "url": "https://lock-me.com",
          "availability": "https://schema.org/InStock"
        }
      }
    `}
  </script>
</Helmet>


  const Container = styled.div`
    ${tw`relative -mx-8 -mt-8`}
  `;

  const TwoColumn = tw.div`flex flex-col lg:flex-row bg-gray-100`;
  const LeftColumn = styled.div`
    ${tw`w-full lg:w-1/3 p-8 lg:p-0`}
    background-color: #1a1a1a;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center; /* Center text horizontally */
    padding-left: 2rem;
    padding-right: 2rem;
  `;

  const RightColumn = styled.div`
    background-image: url("https://storage.googleapis.com/mylock-web.appspot.com/capture_lockers_whatsapp_2000x2000.webp");
    ${tw`bg-cover bg-no-repeat lg:w-2/3 flex flex-col`};
    background-size: cover; /* Ensure the image covers the entire area */
    background-position: left; /* Align the image from the left */
    width: 100%; /* Set width to 100% to occupy the full width of the container */
    justify-content: flex-end; /* Align content to the bottom */
    position: relative; /* Ensure any content inside stays relative */
    aria-label: "WhatsApp-powered AI Smart Lockers"; /* Added for accessibility */
  `;

  const VisuallyHiddenDescription = styled.p`
    ${tw`sr-only`} /* This utility class hides the text visually but keeps it accessible to screen readers */
  `;

  const Content = tw.div`mt-24 lg:mt-24 lg:mb-24 flex flex-col sm:items-center lg:items-stretch`;
  const Heading = tw.h2`text-4xl sm:text-4xl md:text-4xl lg:text-4xl font-black leading-none`;
  const SubHeading = tw.h3`text-2xl font-semibold mt-8`;

  const Paragraph = tw.p`max-w-screen-lg my-8 lg:my-5 lg:my-8 sm:text-lg lg:text-base xl:text-lg leading-loose`;

  const Actions = styled.div`
    ${tw`flex justify-center mb-8`} /* Center buttons */
    .action {
      ${tw`text-center inline-block w-full sm:w-48 py-4 font-semibold tracking-wide rounded hocus:outline-none focus:shadow-outline transition duration-300`}
    }
    .primaryAction {
      ${tw`bg-primary-500 text-gray-100 hover:bg-primary-700 mr-4`}
    }
    .secondaryAction {
      ${tw`bg-gray-300 text-gray-700 hover:bg-gray-400 hover:text-gray-800`}
    }
  `;

  export default ({
    heading = (
      <>
        Conversational
        <wbr />
        <br />
        <span tw="text-primary-500"> smart lockers</span>
      </>
    ),
    description = (
      <>
        <p><SubHeading>AI-driven Smart Lockers</SubHeading></p>
        <p> Request a locker, pay and open the door. </p>
        <p> All through a simple WhatsApp chat. </p>
        <p> Tailored conversational flows to your needs.  </p>
        <p> Higher Engagement and Conversion Rates. </p>
        <p> Faster transactions. </p>
      </>
    ),
      
    primaryActionUrl = "mailto:info@mylock.es?subject=Demo%20whatsapp%20lockers",  // Mailto link with subject
    primaryActionText = "Request a demo",
    secondaryActionUrl = "https://www.lock-me.com",  // External link
    secondaryActionText = "View details"
  }) => {
    return (
      <Container id = "ChatLockers">
        <TwoColumn>
          <LeftColumn>
            <Content>
              <Heading>{heading}</Heading>
              <Paragraph>{description}</Paragraph>
            </Content>
          </LeftColumn>
          <RightColumn aria-label="WhatsApp-powered AI Smart Lockers">
            {/* Hidden description for SEO */}
            <VisuallyHiddenDescription>
              WhatsApp-powered AI Smart Lockers: Request, pay, and open doors via WhatsApp for higher engagement and faster transactions.
            </VisuallyHiddenDescription>
            <Content style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', height: '100%' }}>
              <Actions>
                <a href={primaryActionUrl} className="action primaryAction">
                  {primaryActionText}
                </a>
                <a href={secondaryActionUrl} className="action secondaryAction" target="_blank" rel="noopener noreferrer">
                  {secondaryActionText}
                </a>
              </Actions>
            </Content>
          </RightColumn>
        </TwoColumn>
      </Container>
    );
  };
