// src/components/helpers/lazyload.js
import React, { useRef, useEffect, useState } from 'react';

const LazyLoad = ({ children }) => {
  const ref = useRef();
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (ref.current) {
      const { top } = ref.current.getBoundingClientRect();
      if (top < window.innerHeight && top >= 0) {
        setIsVisible(true);
        window.removeEventListener('scroll', handleScroll);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div ref={ref}>
      {isVisible ? children : <div style={{ height: '200px' }} />} {/* Placeholder */}
    </div>
  );
};

export default LazyLoad;